@use "@angular/material" as mat;

// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600");
$font-family: "Open Sans", sans-serif;

// Theme Colors
$primary-main: #3d3e62;
$primary-lighter: #52537e;
$primary-darker: #2a2b46;

$accent-main: #14c345;
$accent-lighter: #b9edc7;
$accent-darker: #0bae2e;

$bright-main: #ff6333;
$bright-lighter: #f56d43;
$bright-darker: #e54d1e;

$override-main: #96bb3c;
$override-lighter: #abd051;
$override-darker: #7b8f3e;

@import "../base-variables";
@import "../mixins";

// Overwrite base values here
$logo_png: "../../images/venafi/logo.png";
$logo_svg: "../../images/venafi/logo.svg";

// Header padding-left
$header-padding-left: 145px;

// Header Background position
$header-background-position: 15px center;

// Header Colors
$header-background-color: $primary-main;
$header-color: rgba(255, 255, 255, 0.75);
$header-menu-hover-background-color: $primary-lighter;
$header-menu-hover-color: #fff;

$primary-palette: (
    50: #e9ebef,
    100: #c8ccda,
    200: #a6abc0,
    300: #858aa6,
    400: #6b7193,
    500: #545983,
    600: #4d517b,
    700: #45486f,
    800: #3d3e62,
    900: #302e49,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);
$accent-palette: (
    50: #e7f8e9,
    100: #c5edc9,
    200: #9ee2a6,
    300: #72d781,
    400: #4ccd64,
    500: #14c346,
    600: #00b33c,
    700: #00a030,
    800: #008f23,
    900: #007007,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);
$warn-palette: (
    50: #fee3e8,
    100: #fbb8c5,
    200: #f88aa0,
    300: #f35a7a,
    400: #ed355f,
    500: #e70945,
    600: #d70044,
    700: #c20042,
    800: #af0040,
    900: #8d003c,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);

$theme-primary: mat.m2-define-palette($primary-palette, 800, 300, 700);
$theme-accent: mat.m2-define-palette($accent-palette, 500, 300, 700);
$theme-warn: mat.m2-define-palette($warn-palette);
